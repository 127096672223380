import React from 'react';
import styled from 'styled-components';
import { Nav } from '@catalogo/core-nav';
import { OutOfStockText } from '@catalogo/theme-product-card';
import ProductInfo from '../molecules/product-info';
import ImageInfo from '../molecules/image-info';

const OutOfStockCardList = ({ url, ...cardProps }) => (
  <Wrapper to={url}>
    <ImageInfo {...cardProps} />
    <ContainerInfo>
      <ProductInfo {...cardProps} />
      <OutOfStockText />
    </ContainerInfo>
  </Wrapper>
);
export default OutOfStockCardList;

const Wrapper = styled(Nav)`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
`;

const ContainerInfo = styled.div`
  flex: 1 1 auto;
  padding: 16px;
  background-color: #fff;

  .outofstock-text {
    padding: 0;
  }
`;
