import React from 'react';
import styled from 'styled-components';
import { Nav } from '@catalogo/core-nav';
import { useLocation } from 'react-router-dom';
import { ImageInfo, PriceInfo, PriceInfoRange } from '@catalogo/theme-product-card';
import ProductInfo from '../molecules/product-info';
import BadgesInfo from '../molecules/badges-info';
import ListStamps from '../molecules/list-stamps';
import Market from '../molecules/market';

const InStockCard = props => {
  const { history, url, handleClick, price, isMarketProduct } = props;
  const location = useLocation();

  const handleClickMarket = e => {
    if (isMarketProduct) {
      e.preventDefault();
      history.push(`${location?.search}#modal-search-mercado`, { redirectTo: url });
    }
    handleClick && handleClick();
  };

  const cashbackProps = {
    cashback: price?.cashback,
    isSuperCashback: !!price?.cashback,
  };

  return (
    <div>
      <Nav to={url} onClick={e => handleClickMarket(e)}>
        <ImageInfo {...props} />
        <Container>
          <ProductInfo {...props} showAvailableSku={true} />
          {isMarketProduct ? (
            <PriceInfoRange promotionalPrice={price?.promotionalPrice} range={price?.range} />
          ) : (
            <StyledPriceInfo {...props} cashbackProps={cashbackProps} showCashbackMessage />
          )}
          <MarketUI {...props} />
          <ListStampsUI {...props} />
        </Container>
        <BadgesInfo {...props} />
      </Nav>
    </div>
  );
};

export default InStockCard;

const Container = styled.div`
  padding: 16px;
`;

export const StyledPriceInfo = styled(PriceInfo)`
  .price-info-container-discount,
  .installment {
    height: auto;
  }
`;

const ListStampsUI = styled(ListStamps)`
  margin-top: 8px;
`;

const MarketUI = styled(Market)`
  margin-top: 8px;
`;
