import React from 'react';
import styled from 'styled-components';
import { Nav } from '@catalogo/core-nav';
import { useLocation } from 'react-router-dom';
import { PriceInfo as ThemePriceInfo, PriceInfoRange } from '@catalogo/theme-product-card';
import ImageInfo from '../molecules/image-info';
import BadgesInfo from '../molecules/badges-info';
import ListStamps from '../molecules/list-stamps';
import ProductInfo from '../molecules/product-info';
import Market from '../molecules/market';

const InStockCardList = ({ url, handleClick, history, ...cardProps }) => {
  const location = useLocation();

  const handleClickMarket = e => {
    if (cardProps?.isMarketProduct) {
      e.preventDefault();
      history.push(`${location?.search}#modal-search-mercado`, { redirectTo: url });
    }
    handleClick && handleClick();
  };

  const cashbackProps = {
    cashback: cardProps?.price?.cashback,
    isSuperCashback: !!cardProps?.price?.cashback,
  };

  return (
    <Wrapper to={url} onClick={e => handleClickMarket(e)}>
      <ContainerTop>
        <ImageInfo {...cardProps} />
        <ContainerInfo>
          <ProductInfo {...cardProps} />
          {cardProps?.isMarketProduct ? (
            <PriceInfoRange promotionalPrice={cardProps?.price?.promotionalPrice} range={cardProps?.price?.range} />
          ) : (
            <StyledPriceInfo {...cardProps} cashbackProps={cashbackProps} showCashbackMessage />
          )}
          <MarketUI {...cardProps} />
          <ListStamps {...cardProps} />
        </ContainerInfo>
      </ContainerTop>
      <BadgesInfoUI {...cardProps} gap="4px" />
    </Wrapper>
  );
};
export default InStockCardList;

const Wrapper = styled(Nav)`
  width: 100%;
`;

const ContainerTop = styled.div`
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
`;

const ContainerInfo = styled.div`
  flex: 1 1 auto;
  padding: 15px 15px 15px 16px;
  background-color: #fff;
`;

const StyledPriceInfo = styled(ThemePriceInfo)`
  .list-price {
    font-size: 16px;
  }

  .price-info-container-discount,
  .installment {
    height: auto;
  }
`;

const BadgesInfoUI = styled(BadgesInfo)`
  flex: 0 0 100%;
`;

const MarketUI = styled(Market)`
  margin-top: 8px;
`;
