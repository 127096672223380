import React from 'react';
import styled, { css } from 'styled-components';
import { ProductInfo as ThemeProductInfo } from '@catalogo/theme-product-card';

const ProductInfo = props => <StyledProductInfo {...props} showAvailableSku={true} />;

const StyledProductInfo = styled(ThemeProductInfo)`
  .product-name {
    ${({ theme }) => css`
      font-size: ${theme.fontSize.xs};
      line-height: ${theme.fontSize.sm};
      height: auto;
      max-height: 48px;
      -webkit-line-clamp: 3;
    `}
  }
`;

export default ProductInfo;
