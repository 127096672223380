import React from 'react';
import styled from 'styled-components';
import { Nav } from '@catalogo/core-nav';
import { OutOfStockText } from '@catalogo/theme-product-card';
import ProductInfo from '../molecules/product-info';
import ImageInfo from '../molecules/image-info';

const OutOfStockCardList = ({ url, handleClick, ...cardProps }) => (
  <Wrapper to={url} onClick={handleClick}>
    <ContainerImage>
      <ImageInfo {...cardProps} />
    </ContainerImage>
    <Container>
      <ContainerInfo>
        <ProductInfo {...cardProps} />
        <OutOfStockText />
      </ContainerInfo>
    </Container>
  </Wrapper>
);
export default OutOfStockCardList;

const Wrapper = styled(Nav)`
  width: 100%;
  min-height: 200px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  background-color: #fafafa;
`;

const Container = styled.div`
  padding: 16px 48px 16px 16px;
  background-color: #fff;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
`;

const ContainerInfo = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  flex: 1 1 auto;

  .outofstock-text {
    flex: 0 0 174px;
    margin: 0 0 0 72px;
    padding: 0;
    align-self: center;
    text-align: center;

    &-content {
      font-size: 18px;
    }
  }
`;

const ContainerImage = styled.div`
  flex: 0 0 256px;
  height: 100%;
  padding: 16px;
  background: #fafafa;
  margin: auto;
`;
