import React from 'react';
import recProductCardQuery from '@catalogo/service-americanas-product-card/src/queries/rec-product-card.graphql';
import { withRecUnitExperiment } from '@catalogo/service-americanas-common/src/hocs/experiments/rec-unit-experiment';
import Content from './components/content';
import { IProductCardRecUnit } from './types';
import * as Styled from './styles';

export const ProductCardRecUnit: React.FC<IProductCardRecUnit> = ({ publication }) => {
  const productId = publication?.products?.[0]?.id;

  if (!productId) {
    return <></>;
  }

  return (
    <Styled.Wrapper>
      <Styled.Title>{publication?.title}</Styled.Title>
      <Content id={productId} query={recProductCardQuery} />
    </Styled.Wrapper>
  );
};

export default withRecUnitExperiment(ProductCardRecUnit);
