import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: 20px 12px;
`;

export const Title = styled.h2`
  text-transform: lowercase;
  font-weight: bold;
  font-size: 18px;
  color: ${({ theme }) => theme.grey.dark};
  padding: 0 0 10px 5px;
`;
