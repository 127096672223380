import React from 'react';
import styled from 'styled-components';
import { Nav } from '@catalogo/core-nav';
import { OutOfStockText } from '@catalogo/theme-product-card';
import ProductInfo from '../molecules/product-info';
import ImageInfo from '../molecules/image-info';

const OutofStockCard = props => (
  <Wrapper>
    <Nav to={props.url}>
      <ImageInfo {...props} />
      <ProductInfo {...props} />
      <OutOfStockText />
    </Nav>
  </Wrapper>
);

const Wrapper = styled.div`
  margin: 0px auto;
`;

export default OutofStockCard;
