import React from 'react';
import styled from 'styled-components';
import { ImageInfo as ThemeImageInfo } from '@catalogo/theme-product-card';

export const ImageInfo = props => <StyledImageInfo {...props} />;

export default ImageInfo;

const StyledImageInfo = styled(ThemeImageInfo)`
  height: 100%;
  flex: 0 0 137px;
  aspect-ratio: initial;
`;
