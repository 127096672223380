import React from 'react';
import styled from 'styled-components';
import { ProductInfo as ThemeProductInfo } from '@catalogo/theme-product-card';

export const ProductInfo = props => (
  <StyledProductInfo {...props} color="#999" fontSize="14px" showAvailableSku={true} />
);

export default ProductInfo;

export const StyledProductInfo = styled(ThemeProductInfo)`
  margin-bottom: 8px;

  .product-name {
    font-size: ${({ theme }) => theme.fontSize.md};
    line-height: 23px;
    height: auto;
    max-height: 69px;
    -webkit-line-clamp: 3;
  }
`;
