import React from 'react';
import styled from 'styled-components';
import { Nav } from '@catalogo/core-nav';
// import PriceInfo from '../molecules/price-info';
import { PriceInfo as ThemePriceInfo } from '@catalogo/theme-product-card';
import ImageInfo from '../molecules/image-info';
import BadgesInfo from '../molecules/badges-info';
import ListStamps from '../molecules/list-stamps';
import ProductInfo from '../molecules/product-info';

const InStockCardList = ({ url, handleClick, ...cardProps }) => {
  const cashbackProps = {
    cashback: cardProps?.price?.cashback,
    isSuperCashback: !!cardProps?.price?.cashback,
  };

  return (
    <Wrapper to={url} onClick={handleClick}>
      <ContainerImage>
        <ImageInfo {...cardProps} />
      </ContainerImage>
      <Container>
        <BadgesInfoUI {...cardProps} />
        <ContainerInfo>
          <ProductInfo {...cardProps} />
          <ContainerPrice>
            <StyledPriceInfo {...cardProps} cashbackProps={cashbackProps} showCashbackMessage />
            <ListStamps {...cardProps} />
          </ContainerPrice>
        </ContainerInfo>
      </Container>
    </Wrapper>
  );
};

export default InStockCardList;

const Wrapper = styled(Nav)`
  position: relative;
  width: 100%;
  min-height: 200px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  background-color: #fafafa;
`;

const Container = styled.div`
  padding: 16px 48px 16px 16px;
  background-color: #fff;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
`;

const ContainerInfo = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  flex: 1 1 auto;
`;
const ContainerPrice = styled.div`
  flex: 0 0 174px;
  margin-left: 72px;
`;

const ContainerImage = styled.div`
  flex: 0 0 256px;
  height: 100%;
  padding: 16px;
  background: #fafafa;
  margin: auto;
`;

const StyledPriceInfo = styled(ThemePriceInfo)`
  padding: 0;

  .sales-price,
  .installment {
    font-size: 14px;
  }

  .list-price {
    font-size: 24px;
  }

  .price-info-container-discount,
  .installment {
    height: auto;
  }
`;

const BadgesInfoUI = styled(BadgesInfo)`
  flex: 0 0 calc(100% - 246px);
  margin-right: 246px;
  margin-bottom: 12px;
`;
