import React from 'react';
import styled from 'styled-components';
import withProductCard from '@catalogo/service-americanas-product-card/src/hocs/product-card';
import InStockCard from './components/organisms/inStockCard';
import OutOfStockCard from './components/organisms/outOfStockCard';

export const ProductCard = props => {
  const { outOfStock, className, handleClick } = props;

  return (
    <Wrapper className={className} onClick={handleClick}>
      {!outOfStock && <InStockCard {...props} className="" />}
      {outOfStock && <OutOfStockCard {...props} className="" />}
    </Wrapper>
  );
};

export default withProductCard(ProductCard);

const Wrapper = styled.div`
  border-top: 1px solid #e5e5e5;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14);
  overflow: hidden;
  height: 100%;
  max-width: 325px;
`;
