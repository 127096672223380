import React from 'react';
import { useTestAB } from '@catalogo/core-experiments';
import { IProductCardRecUnit } from '@catalogo/ui-americanas-mobile-product-card-rec-unit/src/types';
import { IInjectedProps } from './types';

export const withRecUnitExperiment = <P extends IProductCardRecUnit>(Component: React.FC<P & IInjectedProps>) => (
  props: P
) => {
  const { publication } = props;
  const productId = publication?.products?.[0]?.id;
  const experiment = productId ? useTestAB('EXP_NS_06_REC_UNITARIA') : 'OLD';

  if (experiment?.toUpperCase() !== 'NEW') {
    return <></>;
  }

  return <Component {...props} experiment={experiment} />;
};
