import React from 'react';
import styled from 'styled-components';
import { CashbackBadge, FreightTag, FastDeliveryTag } from '@catalogo/theme-badges';

const ListStamps = ({ ...cardProps }) => (
  <Container>
    <FreightTag {...cardProps} />
    <FastDeliveryTag {...cardProps} />
    <CashbackBadge {...cardProps} />
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  margin-top: 8px;

  &:empty {
    display: none;
  }

  .freight-badge,
  .fast-delivery-badge,
  .cashback-badge {
    &-text {
      font-size: ${({ theme }) => theme.fontSize.xs};
    }
  }
`;

export default ListStamps;
