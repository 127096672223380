import React from 'react';
import styled from 'styled-components';
import withProductCard from '@catalogo/service-americanas-product-card/src/hocs/product-card';
import InStockCardList from './components/organisms/inStockCardList';
import OutOfStockCardList from './components/organisms/outOfStockCardList';

export const ProductCardList = ({ outOfStock, className, ...cardProps }) => (
  <Wrapper className={className}>
    {!outOfStock && <InStockCardList {...cardProps} />}
    {outOfStock && <OutOfStockCardList {...cardProps} />}
  </Wrapper>
);

const Wrapper = styled.div`
  border-top: 1px solid #e5e5e5;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14);
  overflow: hidden;
`;

export default withProductCard(ProductCardList);
