import React from 'react';
import styled from 'styled-components';
import { Nav } from '@catalogo/core-nav';
import { ImageInfo, OutOfStockText } from '@catalogo/theme-product-card';
import ProductInfo from '../molecules/product-info';

const OutOfStockCard = ({ url, ...cardProps }) => (
  <Wrapper>
    <Nav to={url}>
      <ImageInfo {...cardProps} />
      <ProductInfo {...cardProps} />
      <OutOfStockText />
    </Nav>
  </Wrapper>
);

export const Wrapper = styled.div`
  .product-info {
    padding: 16px;
  }
`;

export default OutOfStockCard;
