import React from 'react';
import styled from 'styled-components';
import {
  InternationalBadge,
  PreSaleBadge,
  RecommendedBadge,
  UsedBadge,
  RewrappedBadge,
  MarketBadge,
  AdsBadge,
} from '@catalogo/theme-badges';

const BottomBadges = ({ ...cardProps }) => (
  <Container>
    <RecommendedBadge {...cardProps} />
    <InternationalBadge {...cardProps} />
    <PreSaleBadge {...cardProps} />
    <RewrappedBadge {...cardProps} />
    <UsedBadge {...cardProps} />
    <AdsBadge {...cardProps} />
    <MarketBadge {...cardProps} />
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  &:empty {
    display: none;
  }
`;

export default BottomBadges;
