import React from 'react';
import styled from 'styled-components';
import NearbyStores from '../atoms/nearby-stores';

const Market = ({ className, isMarketProduct, seller, ...cardProps }) => (
  <Container className={className}>
    <NearbyStores {...cardProps} market={isMarketProduct ? seller : null} />
  </Container>
);

const Container = styled.div`
  &:empty {
    display: none;
  }
`;
export default Market;
