import React from 'react';
import styled from 'styled-components';
import { withFastDeliveryExperiment } from '@catalogo/service-americanas-search/src/experiments/fast-delivery-experiment';
import { DiscountProgressiveBadge } from '@catalogo/ui-americanas-mobile-badges';
import { CashbackBadge, FastDeliveryTag, FreightTag } from '@catalogo/theme-badges';

export const ListStamps = ({ className, fastDeliveryExperiment, ...cardProps }) => (
  <Container className={className}>
    <FreightTag {...cardProps} />
    {fastDeliveryExperiment !== 'NEW' && <FastDeliveryTag {...cardProps} />}
    <DiscountProgressiveBadge discountProgressiveStamp={cardProps?.stamps?.discountProgressiveStamp} />
    <CashbackBadge {...cardProps} />
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;

  &:empty {
    display: none;
  }
`;

export default withFastDeliveryExperiment(ListStamps);
