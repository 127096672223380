import React from 'react';
import styled from 'styled-components';
import { ImageInfo as ThemeImageInfo } from '@catalogo/theme-product-card';

export const ImageInfo = props => <StyledImageInfo {...props} />;

export default ImageInfo;

const StyledImageInfo = styled(ThemeImageInfo)`
  height: 100%;
  margin-bottom: 0px;
  max-height: 179px;
  max-width: 179px;
  margin: 0 auto;

  .card-image {
    width: 100%;
    padding: 0;
  }

  .image-info-stamp {
    left: 32px;
    top: 32px;
  }
`;

/*
const Wrapper = styled.div`
  max-height: 179px;
  max-width: 179px;
  margin: 0 auto;
`;

const EventStampPosition = styled(EventStamp)`
  position: absolute;
  left: 32px;
  top: 32px;
  z-index: 2;
`;
*/
