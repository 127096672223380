import React from 'react';
import styled from 'styled-components';
import {
  InternationalBadge,
  PreSaleBadge,
  RecommendedBadge,
  UsedBadge,
  RewrappedBadge,
  AdsBadge,
} from '@catalogo/theme-badges';

const BadgesInfo = ({ className, ...cardProps }) => (
  <Container className={className}>
    <RecommendedBadge {...cardProps} />
    <InternationalBadge {...cardProps} />
    <PreSaleBadge {...cardProps} />
    <RewrappedBadge {...cardProps} />
    <UsedBadge {...cardProps} />
    <AdsBadge {...cardProps} />
  </Container>
);

const Container = styled.div`
  display: flex;
  gap: 8px 16px;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;

  &:empty {
    display: none;
  }

  .recommended-badge,
  .international-badge,
  .pre-sale-badge,
  .rewrapped-badge,
  .used-badge,
  .ads-badge {
    &-text {
      font-size: ${({ theme }) => theme.fontSize.xs};
    }
  }
`;

export default BadgesInfo;
