import React from 'react';
import styled from 'styled-components';
import { ProductInfo as ThemeProductInfo } from '@catalogo/theme-product-card';

export const ProductInfo = ({ ...cardProps }) => <StyledProductInfo {...cardProps} showAvailableSku={true} />;

export default ProductInfo;

export const StyledProductInfo = styled(ThemeProductInfo)`
  .product-name {
    height: auto;
    max-height: 42px;
    -webkit-line-clamp: 3;
  }
`;
