import React from 'react';
import styled from 'styled-components';
import { withFastDeliveryExperiment } from '@catalogo/service-americanas-search/src/experiments/fast-delivery-experiment';
import { DiscountProgressiveBadge } from '@catalogo/ui-americanas-mobile-badges';
import { AdsBadge, MarketBadge, FreightTag, CashbackBadge, FastDeliveryTag } from '@catalogo/theme-badges';

export const BadgesBottomInfo = props => (
  <>
    <Wrapper>
      <FreightTag {...props} />
      {props.fastDeliveryExperiment !== 'NEW' && <FastDeliveryTag {...props} />}
      <DiscountProgressiveBadge discountProgressiveStamp={props?.stamps?.discountProgressiveStamp} />
      <CashbackBadge {...props} />
    </Wrapper>
    <Wrapper>
      <AdsBadge {...props} />
    </Wrapper>
    <MarketBadge {...props} />
  </>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  padding-left: 14px;
  margin-bottom: 8px;

  &:empty {
    display: none;
  }

  .freight-badge,
  .fast-delivery-badge,
  .cashback-badge,
  .ads-badge,
  .market-badge {
    &-text {
      font-size: ${({ theme }) => theme.fontSize.xs};
    }
  }
`;

export default withFastDeliveryExperiment(BadgesBottomInfo);
