import React from 'react';
import styled, { css } from 'styled-components';
import { Nav } from '@catalogo/core-nav';
import { useLocation } from 'react-router-dom';
import { PriceInfo, PriceInfoRange } from '@catalogo/theme-product-card';
import ImageInfo from '../molecules/image-info';
import ProductInfo from '../molecules/product-info';
import BadgesTopInfo from '../molecules/badges-top-info';
import BadgesBottomInfo from '../molecules/badges-bottom-info';
import Market from '../molecules/market-info';

const InStockCard = props => {
  const { history, url, handleClick, className, price, isMarketProduct } = props;
  const location = useLocation();
  const cashbackProps = {
    cashback: price?.cashback,
    isSuperCashback: !!price?.cashback,
  };

  const handleClickMarket = e => {
    if (isMarketProduct) {
      e.preventDefault();
      history.replace(`${location?.search}#modal-search-mercado`, { redirectTo: url });
    }
    handleClick && handleClick();
  };

  return (
    <Wrapper className={className}>
      <Link to={url} onClick={e => handleClickMarket(e)}>
        <ImageInfo {...props} />
        <BadgesTopInfo {...props} />
        <ProductInfo {...props} />
        {isMarketProduct ? (
          <PriceInfoRange promotionalPrice={price?.promotionalPrice} range={price?.range} />
        ) : (
          <StyledPriceInfo {...props} cashbackProps={cashbackProps} showCashbackMessage />
        )}
        <MarketUI {...props} />
        <BadgesBottomInfo {...props} />
      </Link>
    </Wrapper>
  );
};

export default InStockCard;

const Wrapper = styled.div`
  height: 100%;
  padding-bottom: 16px;
`;

const Link = styled(Nav)`
  display: flex;
  flex-direction: column;
  height: 100%;

  > div {
    flex: 000;
  }
`;

export const StyledPriceInfo = styled(PriceInfo)`
  margin-bottom: 8px;

  .sales-price,
  .installment {
    ${({ theme }) => css`
      font-size: ${theme.fontSize.xs};
      height: auto;
    `}
  }

  .list-price {
    ${({ theme }) => css`
      font-size: ${theme.fontSize.xl};
    `};
  }
`;

const MarketUI = styled(Market)`
  margin-bottom: 8px;
  padding: 0 16px;
`;
