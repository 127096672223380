import React from 'react';
import styled from 'styled-components';

const NearbyStores = ({ market, className }) => {
  if (!market) {
    return null;
  }

  const { image, name } = market;

  return (
    <Wrapper className={className}>
      <Image src={image?.main} />
      <Text>
        entregue por <Brand>{name?.toLowerCase()}</Brand>
        <Highlighter>loja próxima</Highlighter>
      </Text>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
`;

const Image = styled.img`
  margin-right: 8px;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  overflow: hidden;
  width: 50px;
  height: 50px;
  box-sizing: content-box;
`;

const Brand = styled.span`
  display: block;
  font-weight: 700;
  color: #333;
  margin-bottom: 4px;
  font-size: 14px;
  line-height: 16px;
  text-transform: capitalize;
`;

const Text = styled.p`
  color: #999;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
`;

const Highlighter = styled.span`
  color: #008a00;
  display: block;
  margin-top: 4px;
  font-size: 12px;
  line-height: 16px;
`;

export default NearbyStores;
