import React from 'react';
import styled from 'styled-components';
import { ProductInfo as ThemeProductInfo } from '@catalogo/theme-product-card';

const ProductInfo = props => <StyledProductInfo {...props} fontSize="14px" showAvailableSku={true} />;

const StyledProductInfo = styled(ThemeProductInfo)`
  padding: 0;
  margin-bottom: 0;

  .product-name {
    font-size: 21px;
    line-height: 27px;
    height: auto;
    max-height: 79px;
    -webkit-line-clamp: 3;
  }
`;

export default ProductInfo;
