import React from 'react';
import styled from 'styled-components';
import { ImageInfo as ThemeImageInfo } from '@catalogo/theme-product-card';

export const ImageInfo = props => <StyledImageInfo {...props} />;

export default ImageInfo;

const StyledImageInfo = styled(ThemeImageInfo)`
  padding: 16px;

  .card-image {
    width: 224px;
    max-width: 224px;
    padding: 0;
  }
`;
