import React from 'react';
import styled from 'styled-components';
import { InternationalBadge, PreSaleBadge, RecommendedBadge, UsedBadge, RewrappedBadge } from '@catalogo/theme-badges';

export const BadgesTopInfo = ({ rating, isMarketProduct, ...cardProps }) => (
  <Wrapper>
    <RecommendedBadge {...cardProps} />
    <InternationalBadge {...cardProps} />
    <PreSaleBadge {...cardProps} />
    <RewrappedBadge {...cardProps} />
    <UsedBadge {...cardProps} />
  </Wrapper>
);

export default BadgesTopInfo;

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 8px;
  padding: 0 16px;
  margin-bottom: 12px;

  &:empty {
    display: none;
  }

  .recommended-badge,
  .international-badge,
  .pre-sale-badge,
  .rewrapped-badge,
  .used-badge {
    &-text {
      font-size: ${({ theme }) => theme.fontSize.xs};
    }
  }
`;
